import React, { Component } from "react";
import { URLS } from "../../urls";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";

class UserSidebar extends Component {
  render() {
    return (
      <div
        className={classnames({
          ["slide-nav"]: this.props.showSideBar,
        })}
      >
        <div className="sidebar" id="sidebar">
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu">
              <ul>
                {
                  <li className="active">
                    <Link to={URLS.employeeDashboard}>
                      <i className="la la-dashboard"></i> <span>Dasbboard</span>
                    </Link>
                  </li>
                }
                <li>
                  <Link to={URLS.userDashboardProfile}>
                    <i className="la la-users"></i> <span>Profile</span>
                  </Link>
                </li>
                {/* <li>
                <Link to={URLS.userDashboardProjects}>
                  <i className="la la-rocket"></i> <span>Contracts</span>
                </Link>
              </li> */}
                <li>
                  <Link to={URLS.userDashboardProjects}>
                    <i className="la la-rocket"></i> <span>Projects</span>
                  </Link>
                </li>
                <li>
                  <a href={URLS.userDashboardTasks}>
                    <i className="la la-tasks"></i> <span>Tasks</span>
                  </a>
                </li>
                <li>
                  <Link to={URLS.userDashboardPerformance}>
                    <i className="la la-balance-scale"></i>{" "}
                    <span>Performance</span>
                  </Link>
                </li>
                <li>
                  <Link to={URLS.userDashboardTeams}>
                    <i className="la la-group"></i> <span>Teams</span>
                  </Link>
                </li>
                <li>
                  <a
                    href="http://app.petrobasegroup.com/calendar"
                    target="_blank"
                  >
                    <i className="la la-calendar"></i>{" "}
                    <span>Calendar & Events</span>
                  </a>
                </li>
                {/* <li> 
								<a href="/dashboard/inbox"><i className="la la-at"></i> <span>Email</span></a>
							</li> */}
                <li>
                  <a
                    href="http://app.petrobasegroup.com/messenger"
                    target="_blank"
                  >
                    <i className="la la-comments"></i> <span>Chat</span>
                  </a>
                </li>
                <li>
                  <Link to={URLS.userDashboardPolicy}>
                    <i className="la la-file-pdf-o"></i> <span>Policies</span>
                  </Link>
                </li>
                <li>
                  <Link to={URLS.userDashboardLeaves}>
                    <i className="la la-times-circle-o"></i> <span>Leaves</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // User: state.api.User,
    showSideBar: state.sideBarReducer.showOnMobile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSidebar);
