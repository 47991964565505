const SideBarReducer = (
  state = {
    showOnMobile: false,
  },
  action
) => {
  switch (action.type) {
    case "TOGGLE":
      return { showOnMobile: !state.showOnMobile };

    default:
      return state;
  }
};

export default SideBarReducer;
