import React, { Component } from "react";
import { URLS } from "../urls";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import classnames from "classnames";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "dashboard",
      submenuOpen: {
        employee: false,
        payroll: false,
        payrollLevelOne: false,
        jobs: false,
        reports: false,
        settings: false,
      },
      deductionSettings: [],
    };
  }

  changeEmployeeSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.employee = !submenuOpen.employee;
    this.setState({
      submenuOpen,
    });
  };
  changePayrollSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.payroll = !submenuOpen.payroll;
    this.setState({
      submenuOpen,
    });
  };
  changeJobsSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.jobs = !submenuOpen.jobs;
    this.setState({
      submenuOpen,
    });
  };
  changeReportsSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.reports = !submenuOpen.reports;
    this.setState({
      submenuOpen,
    });
  };
  changeActiveTab = (tab) => (e) => {
    this.setState({
      activeTab: tab,
    });
  };
  togglePayrollLevelOne = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.payrollLevelOne = !submenuOpen.payrollLevelOne;
    this.setState({
      submenuOpen,
    });
  };

  changeSettingsSubMenu = (e) => {
    const { submenuOpen } = this.state;
    submenuOpen.settings = !submenuOpen.settings;
    this.setState({
      submenuOpen,
    });
  };

  render() {
    const { submenuOpen, activeTab } = this.state;
    const cookies = new Cookies();
    const isSuperAdmin = cookies.get("isSuperAdmin");

    const scrollParent = {
      height: "100%",
      overflow: "hidden",
      paddingBottom: "70px",
      width: "240px",
    };
    const scrollChild = {
      width: "100%",
      height: "100%",
      overflowY: "scroll",
      boxSizing: "content-box",
      paddingRight: "15px",
    };
    return (
      <div
        className={classnames({
          ["slide-nav"]: this.props.showSideBar,
        })}
      >
        <div className="sidebar" id="sidebar" style={scrollParent}>
          <div className="sidebar-inner" style={scrollChild}>
            <div
              id="sidebar-menu"
              className="sidebar-menu"
              style={{ width: "238px" }}
            >
              <ul>
                <li
                  className={activeTab === "dashboard" ? "active" : ""}
                  onMouseDownCapture={this.changeActiveTab("dashboard")}
                >
                  <Link to={`${URLS.dashboard}`}>
                    <i className="la la-dashboard"></i> <span>Dashboard</span>
                  </Link>
                </li>
                {isSuperAdmin && (
                  <li
                    className={
                      activeTab === "employees" ? "submenu active" : "submenu"
                    }
                    onClick={this.changeActiveTab("employees")}
                  >
                    <a
                      href="Javascript:void(0);"
                      className={
                        submenuOpen.employee ? "noti-dot subdrop" : "noti-dot"
                      }
                      onClick={this.changeEmployeeSubMenu}
                    >
                      <i className="la la-user"></i> <span> Employees</span>{" "}
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={
                        submenuOpen.employee
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li>
                        <Link to="/dashboard/employees">All Employees</Link>
                      </li>
                      {/* <li><Link to="/dashboard/holidays">Holidays</Link></li> */}
                      <li>
                        <Link to="/dashboard/leaves">Leaves (Admin)</Link>
                      </li>
                      {/* <li><Link to="/dashboard/leaves-employee">Leaves (Employee)</Link></li> */}
                      <li>
                        <Link to="/dashboard/leaveSettings">
                          Leave Settings
                        </Link>
                      </li>
                      {/* <li>
                    <Link to="/dashboard/attendance">Attendance (Admin)</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/attendance-employee">
                      Attendance (Employee)
                    </Link>
                  </li> */}
                      <li>
                        <Link to="/dashboard/departments">Departments</Link>
                      </li>
                      <li>
                        <Link to="/dashboard/promotion">Promotion</Link>
                      </li>
                      <li>
                        <Link to="/dashboard/resignation">Resignation</Link>
                      </li>
                      <li>
                        <Link to="/dashboard/termination">Termination</Link>
                      </li>
                      {/* <li>
                    <Link to="/dashboard/designations">Designations</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/timesheet">Timesheet</Link>
                  </li>
                  
                  <li>
                    <Link to="/dashboard/overtime">Overtime</Link>
                  </li> */}
                    </ul>
                  </li>
                )}

                {
                  <li
                    className={
                      activeTab === "payroll" ? "submenu active" : "submenu"
                    }
                    onClick={this.changeActiveTab("payroll")}
                  >
                    <a
                      href="Javascript:void(0);"
                      className={
                        submenuOpen.payroll ? "noti-dot subdrop" : "noti-dot"
                      }
                      onClick={this.changePayrollSubMenu}
                    >
                      <i className="la la-money"></i>
                      <span> Payroll </span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={
                        submenuOpen.payroll
                          ? { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <li>
                        <Link to="/dashboard/process-payroll">
                          Process Payroll
                        </Link>
                      </li>
                      <li className="submenu">
                        <a
                          href="javascript:void(0);"
                          onClick={this.togglePayrollLevelOne}
                        >
                          {" "}
                          <span> Deduction Settings</span>{" "}
                          <span className="menu-arrow"></span>
                        </a>
                        <ul
                          style={
                            submenuOpen.payrollLevelOne
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >
                          <li>
                            <Link to="/dashboard/deduction-settings/payee-tax">
                              Payee Tax
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/deduction-settings/pension">
                              Pension
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/deduction-settings/nsitf">
                              NSITF
                            </Link>
                          </li>
                          {this.props.deductionSettings.map((deduction) => {
                            if (
                              ["payee tax", "pension", "nsitf"].includes(
                                deduction.name
                              )
                            ) {
                              return;
                            }
                            return (
                              <li
                                className="text-capitalize"
                                key={deduction.slug}
                              >
                                <Link to={`${deduction.slug}`}>
                                  {deduction.name}
                                </Link>
                              </li>
                            );
                          })}

                          <li className="">
                            <Link to="/dashboard/deduction-settings/add-new">
                              <i
                                className="la la-plus mr-1"
                                style={{
                                  fontSize: "14px",
                                  marginBottom: "3px",
                                }}
                              ></i>
                              Add New
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/dashboard/job-grade"> Job Grade </Link>
                      </li>

                      <li>
                        <Link to="/dashboard/salary-summary">
                          Salary Summary
                        </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/pay-history"> Pay History </Link>
                      </li>
                      <li>
                        <Link to="/dashboard/deduction-reports">
                          {" "}
                          Deduction Reports{" "}
                        </Link>
                      </li>
                    </ul>
                  </li>
                }

                <li
                  className={activeTab === "clients" ? "active" : ""}
                  onClick={this.changeActiveTab("clients")}
                >
                  <Link to="/dashboard/clients">
                    <i className="la la-users"></i> <span>Clients</span>
                  </Link>
                </li>
                <li
                  className={activeTab === "contracts" ? "active" : ""}
                  onClick={this.changeActiveTab("contracts")}
                >
                  <Link to="/dashboard/contracts">
                    <i className="la la-briefcase"></i> <span>Contracts</span>
                  </Link>
                </li>
                <li
                  className={activeTab === "projects" ? "active" : ""}
                  onClick={this.changeActiveTab("projects")}
                >
                  <Link to="/dashboard/projects">
                    <i className="la la-rocket"></i> <span>Projects</span>
                  </Link>
                </li>
                <li
                  className={activeTab === "tasks" ? "active" : ""}
                  onClick={this.changeActiveTab("tasks")}
                >
                  <a href="/dashboard/tasks">
                    <i className="la la-tasks"></i> <span>Tasks</span>
                  </a>
                </li>
                <li
                  className={activeTab === "teams" ? "active" : ""}
                  onClick={this.changeActiveTab("teams")}
                >
                  <Link to="/dashboard/teams">
                    <i className="la la-group"></i> <span>Teams</span>
                  </Link>
                </li>
                <li
                  className={activeTab === "performance" ? "active" : ""}
                  onClick={this.changeActiveTab("performance")}
                >
                  <Link to="/dashboard/performance">
                    <i className="la la-balance-scale"></i>{" "}
                    <span>Performance</span>
                  </Link>
                </li>
                {/* <li className="submenu">
								<a href="#"><i className="la la-phone"></i> <span> Calls</span> <span className="menu-arrow"></span></a>
								<ul style={{display: "none"}}>
									<li><a href="/dashboard/voiceCall">Voice Call</a></li>
									<li><a href="/dashboard/videoCall">Video Call</a></li>
									<li><a href="/dashboard/outgoingCall">Outgoing Call</a></li>
									<li><a href="/dashboard/incomingCall">Incoming Call</a></li>
								</ul>
							</li>
							<li> 
								<a href="/dashboard/contacts"><i className="la la-book"></i> <span>Contacts</span></a>
							</li> */}
                {/* <li> 
								<a href="/dashboard/leads"><i className="la la-user-secret"></i> <span>Leads</span></a>
							</li> */}
                {/* <li className="submenu">
								<a href="#"><i className="la la-files-o"></i> <span> Accounts </span> <span className="menu-arrow"></span></a>
								<ul style={{display: "none"}}>
									<li><a href="/dashboard/estimates">Estimates</a></li>
									<li><a href="/dashboard/invoices">Invoices</a></li>
									<li><a href="/dashboard/payments">Payments</a></li>
									<li><a href="/dashboard/expenses">Expenses</a></li>
									<li><a href="/dashboard/providentFund">Provident Fund</a></li>
									<li><a href="/dashboard/taxes">Taxes</a></li>
								</ul>
							</li> */}

                {/*
                <li
                  className={
                    activeTab === "payroll" ? "submenu active" : "submenu"
                  }
                  onClick={this.changeActiveTab("payroll")}
                >
                  <a
                    href="Javascript:void(0);"
                    className={
                      submenuOpen.payroll ? "noti-dot subdrop" : "noti-dot"
                    }
                    onClick={this.changePayrollSubMenu}
                  >
                    <i className="la la-money"></i>
                    <span> Payroll </span>
                    <span className="menu-arrow"></span>
                  </a>
                  <ul
                    style={
                      submenuOpen.payroll
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <li>
                      <Link to="/dashboard/salary"> Employee Salary </Link>
                    </li>
                    <li>
                      <Link to="/dashboard/salaryView"> Payslip </Link>
                    </li>
                    <li>
                      <Link to="/dashboard/payrollItems"> Payroll Items </Link>
                    </li>
                  </ul>
                </li>
                */}

                <li
                  className={
                    activeTab === "jobs" ? "submenu active" : "submenu"
                  }
                  onClick={this.changeActiveTab("jobs")}
                >
                  <a
                    href="Javascript:void(0);"
                    className={
                      submenuOpen.jobs ? "noti-dot subdrop" : "noti-dot"
                    }
                    onClick={this.changeJobsSubMenu}
                  >
                    <i className="la la-building"></i>
                    <span> Jobs </span>
                    <span className="menu-arrow"></span>
                  </a>
                  <ul
                    style={
                      submenuOpen.jobs
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <li>
                      <Link to="/dashboard/jobs"> Manage Jobs </Link>
                    </li>
                    <li>
                      <Link to="/dashboard/job-applicants">
                        {" "}
                        Applied Candidates{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li> 
								<a href="/dashboard/tickets"><i className="la la-ticket"></i> <span>Tickets</span></a>
							</li> */}
                <li>
                  <Link to="/not-subscribed">
                    <i className="la la-calendar"></i>{" "}
                    <span>Calendar & Events</span>
                  </Link>
                </li>
                {/* <li> 
								<a href="/dashboard/inbox"><i className="la la-at"></i> <span>Email</span></a>
							</li> */}
                <li>
                  <Link to="/not-subscribed">
                    <i className="la la-comments"></i> <span>Chat</span>
                  </Link>
                </li>
                {/* <li> 
								<a href="/dashboard/assets"><i className="la la-object-ungroup"></i> <span>Assets</span></a>
							</li> */}
                <li
                  className={activeTab === "policies" ? "active" : ""}
                  onClick={this.changeActiveTab("policies")}
                >
                  <Link to="/dashboard/policies">
                    <i className="la la-file-pdf-o"></i> <span>Policies</span>
                  </Link>
                </li>

                <li
                  className={
                    activeTab === "settings" ? "submenu active" : "submenu"
                  }
                  onClick={this.changeActiveTab("settings")}
                >
                  <a
                    href="Javascript:void(0);"
                    onClick={this.changeSettingsSubMenu}
                  >
                    <i className="la la-gear"></i> <span> Settings</span>{" "}
                    <span className="menu-arrow"></span>
                  </a>
                  <ul
                    style={
                      submenuOpen.settings
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <li>
                      <Link to="/dashboard/settings">Company Settings</Link>
                    </li>
                  </ul>
                </li>
                {/* <li
                className={activeTab === "activities" ? "active" : ""}
                onClick={this.changeActiveTab("activities")}
              >
                <Link to="/dashboard/activities">
                  <i className="la la-bell"></i> <span>Activities</span>
                </Link>
              </li>
              <li
                className={activeTab === "users" ? "active" : ""}
                onClick={this.changeActiveTab("users")}
              >
                <Link to="/dashboard/users">
                  <i className="la la-user-plus"></i> <span>Users</span>
                </Link>
              </li> */}
                {/* <li className={activeTab==='reports' ? 'submenu active' : 'submenu'} onClick={this.changeActiveTab('reports')}>
								<a href="Javascript:void(0);" className={submenuOpen.reports ? 'noti-dot subdrop' : 'noti-dot'} 
									onClick={this.changeReportsSubMenu}>
									<i className="la la-pie-chart"></i>
									<span> Reports </span>
									<span className="menu-arrow"></span>
								</a>
								<ul style={submenuOpen.reports ? { display: "block" } : {display: "none"}}>
									<li><Link to={`${URLS.expenseReports}`}> Expense Report </Link></li>
									<li><Link to={`${URLS.invoiceReports}`}> Invoice Report </Link></li>
								</ul>
							</li> */}
                {/* <li
                className={activeTab === "settings" ? "active" : ""}
                onClick={this.changeActiveTab("settings")}
              >
                <Link to={`${URLS.reports}`}>
                  <i className="la la-pie-chart"></i> <span>Reports</span>
                </Link>
              </li>
              <li
                className={activeTab === "settings" ? "active" : ""}
                onClick={this.changeActiveTab("settings")}
              >
                <Link to={`${URLS.settings}`}>
                  <i className="la la-cog"></i> <span>Settings</span>
                </Link>
              </li> */}
                {/* <li className="submenu">
								<a href="#"><i className="la la-columns"></i> <span> Pages </span> <span className="menu-arrow"></span></a>
								<ul style={{display: "none"}}>
									<li><a href="login"> Login </a></li>
									<li><a href="register"> Register </a></li>
									<li><a href="forgotPassword"> Forgot Password </a></li>
									<li><a href="otp"> OTP </a></li>
									<li><a href="lockScreen"> Lock Screen </a></li>
									<li><a href="profile"> Employee Profile </a></li>
									<li><a href="clientProfile"> Client Profile </a></li>
									<li><a href="error404">404 Error </a></li>
									<li><a href="error500">500 Error </a></li>
									<li><a href="blankpage"> Blank Page </a></li>
								</ul>
							</li> */}
                {/* <li> 
								<a href="components"><i className="la la-puzzle-piece"></i> <span>Components</span></a>
							</li> */}
                {/* <li className="submenu">
								<a href="javascript:void(0);"><i className="la la-share-alt"></i> <span>Multi Level</span> <span className="menu-arrow"></span></a>
								<ul style={{display: "none"}}>
									<li className="submenu">
										<a href="javascript:void(0);"> <span>Level 1</span> <span className="menu-arrow"></span></a>
										<ul style={{display: "none"}}>
											<li><a href="javascript:void(0);"><span>Level 2</span></a></li>
											<li className="submenu">
												<a href="javascript:void(0);"> <span> Level 2</span> <span className="menu-arrow"></span></a>
												<ul style={{display: "none"}}>
													<li><a href="javascript:void(0);">Level 3</a></li>
													<li><a href="javascript:void(0);">Level 3</a></li>
												</ul>
											</li>
											<li><a href="javascript:void(0);"> <span>Level 2</span></a></li>
										</ul>
									</li>
									<li>
										<a href="javascript:void(0);"> <span>Level 1</span></a>
									</li>
								</ul>
							</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// export default Sidebar;

const mapStateToProps = (state) => {
  return {
    // User: state.api.User,
    showSideBar: state.sideBarReducer.showOnMobile,
    deductionSettings: state.deductionSettings.deductions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
